import React from "react";

function PoliticasPrivacidad() {

  const politics = (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">
                {" "}
                {/*Top Navigation*/} <small></small>
              </h1>
            </div>
            {/* /.col */}
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>

      <div className="content">
        <div className="container">
          <div className="card card-info">
            <div className="card-header" style={{ textAlign: 'center' }}>
              <h2>
                Políticas de Privacidad
              </h2>
            </div>
            <div className="card-body">
              <div>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Centinela creó la aplicación Storm Alert como una aplicación gratuita. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Este SERVICIO es proporcionado por Centinela sin costo y está diseñado para
                      su uso tal cual.
                    </font>
                  </font>
                </p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Esta página se utiliza para informar a los visitantes sobre mis políticas con respecto a la recopilación, uso y
                      divulgación de información personal si alguien decide usar mi servicio.
                    </font>
                  </font>
                </p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Si elige usar mi Servicio, entonces acepta la recopilación y el uso de información en relación con esta
                      política.
                    </font>
                    <font style={{ verticalAlign: 'inherit' }}>La información personal que recopilo se utiliza para proporcionar y mejorar
                      el Servicio. </font>
                    <font style={{ verticalAlign: 'inherit' }}>No usaré ni compartiré su información con nadie, excepto como se describe
                      en
                      esta Política de privacidad.
                    </font>
                  </font>
                </p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Los términos utilizados en esta Política de privacidad tienen el mismo significado que en nuestros Términos y
                      condiciones, a los que se puede acceder en CentinelaApp a menos que se defina lo contrario en esta Política de
                      privacidad.
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Recolección de información y uso</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Para una mejor experiencia, al usar nuestro Servicio, puedo solicitarle que nos brinde cierta información de
                      identificación personal, que incluye, entre otros, ID de publicidad de Android. </font>
                    <font style={{ verticalAlign: 'inherit' }}>La información que solicito se conservará en su dispositivo y no la
                      recopilaré de ninguna manera.
                    </font>
                  </font>
                </p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      La aplicación utiliza servicios de terceros que pueden recopilar información utilizada para identificarlo.
                    </font>
                  </font>
                </p>
                <div>
                  <p>
                    <font style={{ verticalAlign: 'inherit' }}>
                      <font style={{ verticalAlign: 'inherit' }}>
                        Enlace a la política de privacidad de terceros proveedores de servicios utilizados por la aplicación
                      </font>
                    </font>
                  </p>
                  <ul>
                    <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer">
                      <font style={{ verticalAlign: 'inherit' }}>
                        <font style={{ verticalAlign: 'inherit' }}>Servicios de Google Play</font>
                      </font>
                    </a></li>
                  </ul>
                </div>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Dato de registro</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Quiero informarle que cada vez que utiliza mi Servicio, en caso de un error en la aplicación, recopilo datos e
                      información (a través de productos de terceros) en su teléfono llamados Datos de registro. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Estos datos de registro pueden incluir información como la dirección del
                      Protocolo de Internet ("IP") de su dispositivo, el nombre del dispositivo, la versión del sistema operativo, la
                      configuración de
                      la aplicación cuando utiliza mi Servicio, la hora y la fecha de uso del Servicio y otras estadísticas .
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Galletas</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Las cookies son archivos con una pequeña cantidad de datos que se usan comúnmente como identificadores únicos
                      anónimos. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Estos se envían a su navegador desde los sitios web que visita y se
                      almacenan
                      en la memoria interna de su dispositivo.
                    </font>
                  </font>
                </p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Este servicio no utiliza estas "cookies" explícitamente. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Sin embargo, la aplicación puede usar código de terceros y bibliotecas que
                      usan "cookies" para recopilar información y mejorar sus servicios. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Tiene la opción de aceptar o rechazar estas cookies y saber cuándo se envía
                      una cookie a su dispositivo. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Si elige rechazar nuestras cookies, es posible que no pueda usar algunas
                      partes de este Servicio.
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Proveedores de servicio</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Puedo emplear a empresas e individuos de terceros debido a las siguientes razones:
                    </font>
                  </font>
                </p>
                <ul>
                  <li>
                    <font style={{ verticalAlign: 'inherit' }}>
                      <font style={{ verticalAlign: 'inherit' }}>Para facilitar nuestro servicio;</font>
                    </font>
                  </li>
                  <li>
                    <font style={{ verticalAlign: 'inherit' }}>
                      <font style={{ verticalAlign: 'inherit' }}>Para proporcionar el Servicio en nuestro nombre;</font>
                    </font>
                  </li>
                  <li>
                    <font style={{ verticalAlign: 'inherit' }}>
                      <font style={{ verticalAlign: 'inherit' }}>Para realizar servicios relacionados con el Servicio; </font>
                      <font style={{ verticalAlign: 'inherit' }}>o</font>
                    </font>
                  </li>
                  <li>
                    <font style={{ verticalAlign: 'inherit' }}>
                      <font style={{ verticalAlign: 'inherit' }}>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</font>
                    </font>
                  </li>
                </ul>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Deseo informar a los usuarios de este Servicio que estos terceros tienen acceso a su Información personal.
                    </font>
                    <font style={{ verticalAlign: 'inherit' }}>La razón es realizar las tareas que se les asignaron en nuestro nombre.
                    </font>
                    <font style={{ verticalAlign: 'inherit' }}>Sin embargo, están obligados a no divulgar ni utilizar la información para
                      ningún otro propósito.
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Seguridad</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Valoro su confianza en proporcionarnos su información personal, por lo tanto, nos esforzamos por utilizar medios
                      comercialmente aceptables para protegerla. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Pero recuerde que ningún método de transmisión por Internet o método de
                      almacenamiento electrónico es 100% seguro y confiable, y no puedo garantizar su seguridad absoluta.
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Enlaces a otros sitios</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Este servicio puede contener enlaces a otros sitios. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Si hace clic en un enlace de un tercero, será dirigido a ese sitio. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Tenga en cuenta que estos sitios externos no son operados por mí. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Por lo tanto, le recomiendo que revise la Política de privacidad de estos
                      sitios web. </font>
                    <font style={{ verticalAlign: 'inherit' }}>No tengo control ni asumo ninguna responsabilidad por el contenido, las
                      políticas de privacidad o las prácticas de sitios o servicios de terceros.
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Privacidad infantil</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Estos Servicios no se dirigen a ninguna persona menor de 13 años. No recopilo a sabiendas información de
                      identificación personal de niños menores de 13 años. En el caso de que descubra que un niño menor de 13 años me
                      ha
                      proporcionado
                      información personal, la borro inmediatamente de nuestros servidores. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Si usted es padre o tutor y sabe que su hijo nos ha proporcionado
                      información
                      personal, comuníquese conmigo para que pueda realizar las acciones necesarias.
                    </font>
                  </font>
                </p>
                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Cambios a esta política de privacidad</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Puedo actualizar nuestra Política de privacidad de vez en cuando. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Por lo tanto, se recomienda que revise esta página periódicamente en busca
                      de
                      cambios. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Le notificaré cualquier cambio publicando la nueva Política de privacidad
                      en
                      esta página. </font>
                    <font style={{ verticalAlign: 'inherit' }}>Estos cambios son efectivos inmediatamente después de su publicación en
                      esta
                      página.
                    </font>
                  </font>
                </p>

                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Uso de cámara</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Política de retención y manejo de información
                      Conservaremos los datos (fotos, videos y documentos) brindados por el usuario y los recolectados automáticamente
                      de forma indefinida, ya que
                      se
                      necesita para el uso correcto de la app.
                      Esta compañía no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento.
                    </font>
                  </font>
                </p>

                <p><strong>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Contáctenos</font>
                  </font>
                </strong></p>
                <p>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>
                      Si tiene alguna pregunta o sugerencia sobre mi Política de privacidad, no dude en ponerse en contacto conmigo en
                      soportecentinela@minsur.com
                    </font>
                  </font>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return politics;
}

export default PoliticasPrivacidad;
