import React, { useEffect, useState, useContext } from "react";

import app from "../../../firebase-config";
import Avatar from "../Avatar";

function UsersBox() {
  const [users, setUsers] = useState([]);

  async function getData() {
    await app
      .firestore()
      .collection("users")
      .where("connected", "==", true)
      .orderBy("nombres", "asc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setUsers(docs);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const userbox = (
    <div className="card direct-chat direct-chat-primary">
      <div className="card-header">
        <h3 className="card-title">Usuarios StormAlert</h3>
        <div className="card-tools">
          <span
            data-toggle="tooltip"
            title="3 New Messages"
            className="badge badge-primary"
          ></span>
          <button
            type="button"
            className="btn btn-tool"
            data-card-widget="collapse"
          >
            <i className="fas fa-minus" />
          </button>
          <button
            type="button"
            className="btn btn-tool"
            data-toggle="tooltip"
            title="Contacts"
            data-widget="chat-pane-toggle"
          >
            <i className="fas fa-address-book" />
          </button>
        </div>
      </div>
      {/* /.card-header */}
      <div className="card-body">
        {/* Conversations are loaded here */}
        <div className="direct-chat-messages">
          {/* Message. Default to the left */}
          <div className="direct-chat-msg">
            <ul className="contacts-list">
              {users.map((user) => {
                return (
                  <li key={user.nroDoc}>
                    <a href="#">
                      <div className="avatar small direct-chat-img">
                        <span>
                          {user.nombres.charAt(0)}
                          {user.apellidos.charAt(0)}
                        </span>
                      </div>
                      <div className="contacts-list-info">
                        <span
                          className="contacts-list-name"
                          style={{ color: "#211915" }}
                        >
                          {user.nombres + " " + user.apellidos}
                          <i
                            className="fas fa-circle float-right"
                            style={{
                              color: user.connected ? "greenyellow" : "gray",
                            }}
                          />
                        </span>
                        <span className="contacts-list-msg">
                          {user.cargo + " - " + user.empresa}
                        </span>
                      </div>
                      {/* /.contacts-list-info */}
                    </a>
                  </li>
                );
              })}
            </ul>
            {/* /.contacts-list */}
          </div>
          {/*List Contacts */}
        </div>
        {/* /.direct-chat-pane */}
      </div>
      {/* /.card-footer*/}
    </div>
  );
  return userbox;
}

export default UsersBox;
