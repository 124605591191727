import React, { useContext, useState, useEffect } from "react";

import app from "../../firebase-config";

function ServerStatus() {
  const [server, setServer] = useState({});
  function getData() {
    app
      .firestore()
      .collection("notification")
      .doc("server")
      .onSnapshot((snap) => {
        setServer(snap.data());
      });
  }
  useEffect(() => {
    getData();
  }, []);
  const serverstatus = (
    <>
      {server.status ? (
        <li
          className="mr-3"
          style={{
            borderRadius: 5,
            backgroundColor: "rgba(56, 142, 60,0.2)",
            width: 150,
          }}
        >
          <div className="nav-link">
            <div className="row">
              <span className="col-md-12">
                <i className="fa fa-server text-success mr-2" /> Conectado
              </span>
              <div className="ml-2 pb-2" style={{ fontSize: 10 }}>
                * Server, conectado{" "}
              </div>
            </div>
          </div>
        </li>
      ) : (
        <li
          className="mr-3"
          style={{
            borderRadius: 5,
            backgroundColor: "rgba(211,47,47,0.8)",
            width: 220,
          }}
        >
          <div className="nav-link">
            <div className="row">
              <span className="col-md-12">
                <i className="fa fa-server mr-2" /> Sin conexión ...
              </span>
              <div className="ml-2 pb-2" style={{ fontSize: 12 }}>
                * Server, última conexión{" "}
                {new Date(server.lastConnection).toLocaleDateString()}
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
  return serverstatus;
}

export default ServerStatus;
