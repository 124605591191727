import React, { useEffect } from "react";

import "react-notifications-component/dist/theme.css";
//import "./animate.css";

import "../avatar.css";
import ChatBox from "./ChatBox";
import UsersBox from "./UsersBox";

export default function Chat() {

  useEffect(() => {
    //notificationState();
    //document.getElementById("chatCardBox").scrollIntoView(true);
  });
  const chat = (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  {" "}
                  {/*Top Navigation*/} <small></small>
                </h1>
              </div>
              {/* /.col */}
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <div className="content">
          <div className="container">
            <div className="row">
              {/* /.col-md-6 */}
              <div className="col-lg-8">
                <ChatBox></ChatBox>
              </div>
              {/* /.col-md-6 */}
              <div className="col-lg-4">
                <UsersBox></UsersBox>
              </div>
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content */}
      </div>

      {/* /.content-wrapper */}
    </>
  );
  return chat;
}
