import React, { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "../Content/Login/Login";
import NuevaContraseña from "../Content/NuevaContraseña";
import RecuperarContraseña from "../Content/Login/RecuperarContraseña";
import Inicio from "../Content/Inicio/Inicio";
import Chat from "../Content/Chat/Chat";
import Usuarios from "../Content/Usuarios";
import PoliticasPrivacidad from "../Content/PoliticasPrivacidad";
import EliminarCuenta from "../Content/EliminarCuenta";
import Layout from "../Layout/Layout";

import "react-notifications-component/dist/theme.css";
import "animate.css";

import { AuthContext } from "../../Auth";
import appe from "../../firebase-config";
import PageDisabled from "../Content/PageDisabled";
import PagePending from "../Content/PagePending";
import RegistroProvider from "../Content/Login/RegistroProvider";
import UsuarioPasado from "../Content/Login/UsuarioPasado";
import DataBase from "../Content/DataBase/DataBase";
import { Footer } from "antd/lib/layout/layout";
import { version } from '../../Version';

export default function App() {
  const { userData } = useContext(AuthContext);

  useEffect(() => {
    appe
      .messaging()
      .getToken({
        vapidKey:
          "BC4A7MYW_iMHYNNhIAwmrNt1DBZBI5hP9BdUfgzYHl6Y0iyzWCSfjNfa55FaO-6J4idqAUImpZiYLLxMx8FtJ_w"
      })
      .then((currentToken) => {

      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  }, []);

  if (!localStorage.getItem("usuarioToken")) {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/politicas-privacidad" exact strict component={PoliticasPrivacidad} />
          <Route path="/login" exact strict component={Login} />
          <Route path="/registro" exact strict component={RegistroProvider} />
          <Route
            path="/recuperar"
            exact
            strict
            component={RecuperarContraseña}
          />
          <Route path="/existente" exact strict component={UsuarioPasado} />
          <Redirect to="/login"></Redirect>
        </Switch>
      </BrowserRouter>
    );
  }

  const app = (
    <BrowserRouter>
      <Switch>
        <Route path="/politicas-privacidad" exact strict component={PoliticasPrivacidad} />
        <Route
          path="/login"
          render={() => {
            window.location.replace("/");
          }}
        />
        <Layout>
          {userData.estado === "enabled" ? (
            <>
              <Route path="/" exact strict component={Inicio} />
              <Route path="/chat" exact strict component={Chat} />
              {userData.tipoUsuario === "Administrador" ? (
                <>
                  <Route path="/usuarios" exact strict component={Usuarios} />
                  <Route path="/database" exact strict component={DataBase} />
                </>
              ) : (
                <></>
              )}
              <Route path="/eliminar_cuenta" exact strict component={EliminarCuenta} />

              <Route
                path="/recuperar"
                exact
                strict
                component={RecuperarContraseña}
              />
              <Route path="/nueva" exact strict component={NuevaContraseña} />
              {/*<Route component={PageNotFound} />--Verificar si page not found!*/}
            </>
          ) : userData.estado === "disabled" ? (
            <Route component={PageDisabled} />
          ) : userData.estado === "pending" ? (
            <Route component={PagePending} />
          ) : (
            <>Cargando...</>
          )}

          <Footer style={{ textAlign: 'center', padding: '10px' }}>
            ({version}) StormAlertaWeb©{new Date().getFullYear()} - Created by
            Centinela S&I
          </Footer>
        </Layout>
      </Switch>
    </BrowserRouter>
  );
  return app;
}
