import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../Auth";
import app from "../../../firebase-config";

function ChatBox() {
  const icon_falla = "./assets/icon-falla.png";
  const icon_nord = "./assets/icon-nord.png";
  const icon_precaucion = "./assets/icon-precaucion.png";
  const icon_aviso = "./assets/icon-aviso.png";
  const icon_alarma = "./assets/icon-alarma.png";

  const [message, setMessage] = useState("");
  const [arraymsg, setArraymsg] = useState([]);

  const { currentUser, userData } = useContext(AuthContext);

  async function getData() {
    await app
      .firestore()
      .collection("chat_general")
      .orderBy("time", "desc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setArraymsg(docs);
      });
  }

  async function sendMessageEnter(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      document.getElementById("messageText").value = "";
      const fecha = Date.now().toString();
      if (message !== "") {
        if (!message.match(/^[\s]+$/)) {
          await app.firestore().collection("chat_general").add({
            message: message,
            sendBy: currentUser.email,
            time: fecha,
            userUID: currentUser.uid,
            nombres: userData.nombres,
            apellidos: userData.apellidos,
          });
          await app.firestore().collection("notification").doc("chat").set({
            msg: message,
            nombres: userData.nombres,
            apellidos: userData.apellidos,
          });
        }
      }
    }
  }

  async function sendMessage(event) {
    event.preventDefault();
    document.getElementById("messageText").value = "";
    const fecha = Date.now().toString();
    if (message !== "") {
      if (!message.match(/^[\s]+$/)) {
        await app.firestore().collection("chat_general").add({
          message: message,
          sendBy: currentUser.email,
          time: fecha,
          userUID: currentUser.uid,
          nombres: userData.nombres,
          apellidos: userData.apellidos,
        });
        app.firestore().collection("notification").doc("chat").set({
          msg: message,
          nombres: userData.nombres,
          apellidos: userData.apellidos,
        });
      }
    }
  }

  async function actionMessage(event) {
    await app
      .firestore()
      .collection("chat_general")
      .doc(event.currentTarget.id)
      .delete();
  }

  useEffect(() => {
    getData();
  }, []);

  const chatbox = (
    <div className="card direct-chat direct-chat-primary">
      <div className="card-header">
        <h3 className="card-title">Chat StormAlert</h3>
        <div className="card-tools">
          <span
            data-toggle="tooltip"
            title="3 New Messages"
            className="badge badge-primary"
          ></span>
          <button
            type="button"
            className="btn btn-tool"
            data-card-widget="collapse"
          >
            <i className="fas fa-minus" />
          </button>
          <button
            type="button"
            className="btn btn-tool"
            data-toggle="tooltip"
            title="Contacts"
            data-widget="chat-pane-toggle"
          >
            <i className="fas fa-comments" />
          </button>
        </div>
      </div>
      {/* /.card-header */}
      <div id="chatCardBox" className="card-body">
        <div
          id="chatBox"
          className="direct-chat-messages"
          style={{
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {arraymsg.map((msg, key) => {
            if (msg.userUID === currentUser.uid) {
              return (
                <div
                  key={key}
                  id={msg.userUID}
                  className="direct-chat-msg right"
                >
                  <div className="direct-chat-infos clearfix">
                    <span className="direct-chat-name float-right">
                      {msg.nombres} {msg.apellidos}
                    </span>
                    <span className="direct-chat-timestamp float-left">
                      {new Date(parseInt(msg.time)).toLocaleDateString()}
                    </span>
                  </div>
                  {/* /.direct-chat-infos */}
                  <div className="avatar small direct-chat-img">
                    <span>
                      {msg.nombres.charAt(0)}
                      {msg.apellidos.charAt(0)}
                    </span>
                  </div>

                  <div
                    className="direct-chat-text d-flex justify-content-end"
                    style={{
                      marginLeft: "30%",
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div
                        style={{
                          marginLeft: 40,
                        }}
                      >
                        {msg.message}
                      </div>
                      <div
                        style={{
                          fontSize: "80%",
                          position: "absolute",
                          left: "1%",
                          bottom: "1%",
                        }}
                      >
                        {new Date(parseInt(msg.time)).toLocaleTimeString()}
                      </div>
                    </div>
                    <i
                      type="button"
                      className="d-flex align-items-center dropdown-toggle"
                      data-toggle="dropdown"
                      style={{ paddingLeft: "2%", width: "4%" }}
                    ></i>
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        <a
                          id={msg.id}
                          onClick={(e) => actionMessage(e)}
                          href="#"
                        >
                          Eliminar
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* /.direct-chat-text */}
                </div>
              );
            }
            if (msg.userUID) {
              return (
                <div key={key} id={msg.userUID} className="direct-chat-msg">
                  <div className="direct-chat-infos clearfix">
                    <span className="direct-chat-name float-left">
                      {msg.nombres} {msg.apellidos}
                    </span>
                    <span className="direct-chat-timestamp float-right">
                      {new Date(parseInt(msg.time)).toLocaleDateString()}
                    </span>
                  </div>
                  {/* /.direct-chat-infos */}
                  <div className="avatar2 small direct-chat-img">
                    <span>
                      {msg.nombres.charAt(0)}
                      {msg.apellidos.charAt(0)}
                    </span>
                  </div>
                  {/* /.direct-chat-img */}
                  <div
                    className="direct-chat-text"
                    style={{
                      marginRight: "30%",
                    }}
                  >
                    <div
                      style={{
                        marginRight: 40,
                      }}
                    >
                      {msg.message}
                    </div>
                    <div
                      style={{
                        fontSize: "80%",
                        position: "absolute",
                        right: "1%",
                        bottom: "1%",
                      }}
                    >
                      {new Date(parseInt(msg.time)).toLocaleTimeString()}
                    </div>
                  </div>
                  {/* /.direct-chat-text */}
                </div>
              );
            } else {
              return (
                <div key={key} id={msg.id} className="direct-chat-msg">
                  <div className="direct-chat-infos clearfix">
                    <span className="direct-chat-name float-left">
                      {msg.sendBy}
                    </span>
                    <span className="direct-chat-timestamp float-right">
                      {new Date(parseInt(msg.time)).toLocaleDateString()}
                    </span>
                  </div>
                  {/* /.direct-chat-infos */}
                  <img
                    alt=""
                    className="direct-chat-img"
                    src={
                      msg.estado === 1
                        ? icon_falla
                        : msg.estado === 2
                          ? icon_nord
                          : msg.estado === 3
                            ? icon_precaucion
                            : msg.estado === 4
                              ? icon_aviso
                              : msg.estado === 5
                                ? icon_alarma
                                : ""
                    }
                  ></img>
                  {/* /.direct-chat-img */}
                  <div
                    className="direct-chat-text"
                    style={{
                      backgroundColor:
                        msg.estado === 1
                          ? "rgba(128,128,128,0.7)"
                          : msg.estado === 2
                            ? "rgba(0,187,45,0.7)"
                            : msg.estado === 3
                              ? "rgba(255,255,0,0.7)"
                              : msg.estado === 4
                                ? "rgba(255,165,0,0.7)"
                                : msg.estado === 5
                                  ? "rgba(255,0,0,0.7)"
                                  : "",
                    }}
                  >
                    <div
                      style={{
                        marginRight: 40,
                      }}
                    >
                      {msg.detalle}. Cambio de estado.
                    </div>
                    <div
                      style={{
                        fontSize: "80%",
                        position: "absolute",
                        right: "1%",
                        bottom: "1%",
                      }}
                    >
                      {new Date(parseInt(msg.time)).toLocaleTimeString()}
                    </div>
                  </div>
                  {/* /.direct-chat-text */}
                </div>
              );
            }
          })}
        </div>
      </div>
      {/* /.card-body */}
      <div className="card-footer">
        <div className="input-group">
          <input
            id="messageText"
            type="text"
            placeholder="Escribe aquí ..."
            className="form-control"
            onChange={(e) => setMessage(e.currentTarget.value)}
            onKeyUpCapture={(e) => sendMessageEnter(e)}
          />
          <span className="input-group-append">
            <button
              name="message"
              type="submit"
              className="btn btn-primary"
              onClick={(e) => sendMessage(e)}
            >
              <i className="fas fa-paper-plane mr-2" />
              Enviar
            </button>
          </span>
        </div>
      </div>
      {/* /.card-footer*/}
    </div>
  );
  return chatbox;
}

export default ChatBox;
