import React, { useContext, useState, useEffect } from "react";

import app from "../../firebase-config";
import { store } from "react-notifications-component";

function Notification(props) {
  var audioAlarma = new Audio("./assets/alarm.mp3");
  var audioAviso = new Audio("./assets/aviso.mp3");
  var audioPrecaucion = new Audio("./assets/precaucion.mp3");
  var audioNoRD = new Audio("./assets/nodetectado.mp3");
  var audioFalla = new Audio("./assets/falla.mp3");

  const [volume, setVolume] = useState(0.2);

  async function notificationState() {
    await app
      .firestore()
      .collection("notification")
      .doc("sensor")
      .onSnapshot((snap) => {
        var estado = snap.data().estado;
        var detalle = snap.data().detalle;
        if (estado == 5) {
          audioAlarma.volume = volume;
          audioAlarma.play();
        }
        if (estado == 4) {
          audioAviso.volume = volume;
          audioAviso.play();
        }
        if (estado == 3) {
          audioPrecaucion.volume = volume;
          audioPrecaucion.play();
        }
        if (estado == 2) {
          audioNoRD.volume = volume;
          audioNoRD.play();
        }
        if (estado == 1) {
          audioFalla.volume = volume;
          audioFalla.play();
        }
        store.addNotification({
          title: detalle,
          message: "El estado se ha actualizado",
          type:
            estado == "5"
              ? "danger"
              : estado == "4"
              ? "warning"
              : estado == "3"
              ? "default"
              : estado == "2"
              ? "success"
              : estado == "1"
              ? "info"
              : null,
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true,
            showIcon: true,
          },
        });
      });
  }
  useEffect(() => {
    notificationState();
  }, []);

  const notification = <div></div>;
  return notification;
}

export default Notification;
