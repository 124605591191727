import React, { useContext } from "react";
import "firebase/auth";
import app from "../../firebase-config";
import { AuthContext } from "../../Auth";
import Swal from "sweetalert2";

function EliminarCuenta() {
  const { currentUser } = useContext(AuthContext);

  async function eliminarCuenta(event) {
    event.preventDefault();
    spinnerModal();
    const borrarMiUsuario = app.functions().httpsCallable("borrarUsuario");

    borrarMiUsuario({ idUserAuth: currentUser.uid })
      .then(() => {
        window.location.replace("/login");
        localStorage.clear();
      })
      .catch((error) => {
        console.log(error);
      });

  }

  function spinnerModal() {
    let timerInterval;
    Swal.fire({
      title: "Eliminando cuenta asociada",
      html: "Espere un momento...",
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  }

  const inicio = (
    <div className="hold-transition login-page">

      <form onSubmit={eliminarCuenta}
      >
        <div className="login-box">
          <div className="card">
            <div className="card-body login-card-body">
              <div className="login-logo" style={{ color: "#0B3688" }}>
                <h3>Esta acción eliminará su cuenta actual de StormAlert y los datos asociados.</h3>
                <h5>¿Seguro que desea continuar?</h5>
              </div>

              <div className="col-6 m-2 mx-auto">
                <a
                  href="/"
                  className="btn btn-primary btn-block"
                  style={{
                    background: "#0B3688",
                    border: "#0B3688",
                  }}
                >
                  No, regresar
                </a>
              </div>
              <div className="col-6 m-2 mx-auto">
                <button
                  type="submit"
                  className="btn btn-danger btn-block"
                >
                  Si, eliminar
                </button>
              </div>
              <br></br>
            </div>
          </div>
        </div></form>
    </div>
  );
  return inicio;
}

export default EliminarCuenta;
