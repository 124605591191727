import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { getColumnSearchProps } from "../general/FilterHeaderColumn";

import XLSX from "xlsx";

import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { Chip } from 'primereact/chip';
import Swal from "sweetalert2";
import "react-notifications-component/dist/theme.css";
import "animate.css";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Switch from "react-switch";
import app from "../../firebase-config";
import Registro from "../Modal/Registro";
import EditarUsuario from "../Modal/EditarUsuario";

export default function Usuarios() {
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [usersBuscar, setUsersBuscar] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const columns = [{
    title: "Nombres",
    key: "nombres",
    dataIndex: "nombres",
    align: "center",
    fixed: "left",
    width: 150,
    ...getColumnSearchProps(
      "nombres",
      "Nombres",
      searchedColumn,
      setSearchedColumn,
      searchText,
      setSearchText
    ),
  },
  {
    title: "Apellidos",
    key: "apellidos",
    dataIndex: "apellidos",
    align: "center",
    fixed: "left",
    width: 150,
    ...getColumnSearchProps(
      "apellidos",
      "Apellidos",
      searchedColumn,
      setSearchedColumn,
      searchText,
      setSearchText
    ),
  },
  {
    title: "Doc.",
    key: "tipoDoc",
    dataIndex: "tipoDoc",
    align: "center",
    fixed: "left",
    width: 65,
  },
  {
    title: "Nro. Documento",
    key: "nroDoc",
    dataIndex: "nroDoc",
    align: "center",
    fixed: "left",
    width: 125,
    ...getColumnSearchProps(
      "nroDoc",
      "Nro. Documento",
      searchedColumn,
      setSearchedColumn,
      searchText,
      setSearchText
    ),
  },
  {
    title: "Correo",
    key: "email",
    dataIndex: "email",
    align: "center",
    width: 200,
    ...getColumnSearchProps(
      "email",
      "Correo",
      searchedColumn,
      setSearchedColumn,
      searchText,
      setSearchText
    ),
  },
  {
    title: "Empresa",
    key: "empresa",
    dataIndex: "empresa",
    align: "center",
    width: 175,
    ...getColumnSearchProps(
      "empresa",
      "Empresa",
      searchedColumn,
      setSearchedColumn,
      searchText,
      setSearchText
    ),
  },
  {
    title: "Cargo",
    dataIndex: "cargo",
    key: "cargo",
    align: "center",
    width: 175,
    ...getColumnSearchProps(
      "cargo",
      "Cargo",
      searchedColumn,
      setSearchedColumn,
      searchText,
      setSearchText
    ),
    render: (text) => {
      return (text ? text.toUpperCase() : "-");
    },
  },
  {
    title: "Estado",
    dataIndex: "estado",
    key: "estado",
    width: 85,
    align: "center",
    render: (_, row) => {
      const color = row.estado === "enabled" ? "#2e7d32" : row.estado === "disabled" ? "#c62828" : "#f9a825";
      return (
        <Chip
          style={{
            alignItems: "center",
            padding: 10,
            backgroundColor: color,
            color: "#ffffff",
            fontWeight: "bold",
          }}
        />
      );
    },
  },
  {
    title: "Administrador",
    dataIndex: "tipoUsuario",
    key: "tipoUsuario",
    width: 125,
    align: "center",
    render: (_, row) => {
      return (<Switch
        onChange={() =>
          setAdmin(row.nombres, row.apellidos, row.tipoUsuario, row.idUserAuth)
        }
        checked={_ === "Administrador"} />);
    },
  },
  {
    title: "Acciones",
    dataIndex: "acciones",
    key: "acciones",
    width: 200,
    align: "center",
    fixed: "right",
    render: (_, row) => {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <Popup
              trigger={
                <button
                  id={row.idUserAuth}
                  onClick={(e) =>
                    llenarData(row.idUserAuth)
                  }
                  className="btn btn-block bg-gradient-primary btn-sm"
                  data-toggle="modal"
                  data-target="#modal-lg-edit"
                >
                  <i className="fas fa-edit"></i>
                </button>
              }
              position="top center"
              on={["hover", "focus"]}
            >
              <div>
                Editar Usuario
              </div>
            </Popup>
          </div>
          <div className="col-sm-12 col-md-4">
            {row.estado === "enabled" ? (
              <Popup
                trigger={
                  <button
                    id={row.idUserAuth}
                    className="btn btn-block bg-gradient-success btn-sm"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                }
                position="right center"
              >
                <div className="row">
                  <div className="col-md-8">
                    ¿Deshabilitar al usuario?
                  </div>
                  <button
                    onClick={() =>
                      cambiarEstado(
                        row.estado,
                        row.idUserAuth
                      )
                    }
                    className="btn btn-danger mr-1 col-md-3"
                  >
                    <i className="fas fa-ban"></i>
                  </button>
                </div>
              </Popup>
            ) : row.estado === "disabled" ? (
              <Popup
                trigger={
                  <button
                    id={row.idUserAuth}
                    className="btn btn-block bg-gradient-danger btn-sm "
                  >
                    <i className="fas fa-ban"></i>
                  </button>
                }
                position="right center"
              >
                <div className="row">
                  <div className="col-md-8">
                    ¿Habilitar al usuario?
                  </div>
                  <button
                    onClick={() =>
                      cambiarEstado(
                        row.estado,
                        row.idUserAuth
                      )
                    }
                    className="btn btn-success mr-1 col-md-3"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                </div>
              </Popup>
            ) : (
              <Popup
                trigger={
                  <button
                    id={row.idUserAuth}
                    className="btn btn-block bg-gradient-danger btn-sm "
                  >
                    <i className="fas fa-user-clock"></i>
                  </button>
                }
                position="right center"
              >
                <div className="row">
                  <div className="col-md-8">
                    ¿Habilitar al usuario?
                  </div>
                  <button
                    onClick={() =>
                      cambiarEstado(
                        row.estado,
                        row.idUserAuth
                      )
                    }
                    className="btn btn-danger mr-1 col-md-3"
                  >
                    <i className="fas fa-check"></i>
                  </button>
                </div>
              </Popup>
            )}
          </div>
          <div className="col-sm-12 col-md-4">
            <Popup
              trigger={
                <button
                  id={row.idUserAuth}
                  onClick={(e) =>
                    borrarUsuario(
                      row.nombres,
                      row.apellidos,
                      row.idUserAuth
                    )
                  }
                  className="btn btn-block bg-gradient-secondary btn-sm"
                >
                  <i className="far fa-trash-alt"></i>
                </button>
              }
              position="top center"
              on={["hover", "focus"]}
              arrow="center center"
            >
              <span className="ml-10">
                Eliminar Usuario
              </span>
            </Popup>
          </div>
        </div>
      );
    },
  },
  ];
  /***********Notification Toast************ */

  const ExcelAJSON = (e) => {
    if (e.target.files[0]) {

      const reader = new FileReader();
      reader.onloadend = function (event) {
        const workbook = XLSX.read(event.target.result);

        var nombreHoja = workbook.SheetNames;
        let datos = XLSX.utils.sheet_to_json(workbook.Sheets[nombreHoja[0]]);

        const jDatos = [];
        for (let i = 0; i < datos.length; i++) {
          const dato = datos[i];
          jDatos.push({
            ...dato,
          });
        }
        saveJsonUsers(jDatos);
      };

      reader.readAsArrayBuffer(e.target.files[0]);
    }

  };

  async function saveJsonUsers(users) {
    var created = 0;
    var updated = 0
    var errs = 0;

    const crearUsuario = app.functions().httpsCallable("crearUsuario");

    Swal.fire({
      title: "Registrando Usuarios",
      text: "Cargando usuario " + 1 + " de: " + users.length + ". Espere, por favor.",
      didOpen: () => {
        Swal.showLoading();
      },
    });

    for (const [i, user] of users.entries()) {
      if (
        user.TIPODOC &&
        user.NRODOC &&
        user.NOMBRES &&
        user.APELLIDOS &&
        user.CARGO &&
        user.EMPRESA &&
        user.EMAIL &&
        user.PASSWORD
      ) {
        const usuarios = app.firestore().collection('users');
        const snapshot = await usuarios.where('nroDoc', '==', user.NRODOC.toString()).get();
        Swal.getContent().textContent = "Cargando usuario " + (i + 1) + " de: " + users.length + ". Espere, por favor.";
        if (!snapshot.empty) {
          snapshot.forEach(doc => {
            app
              .firestore()
              .collection("users")
              .doc(doc.id)
              .update({
                nombres: user.NOMBRES,
                apellidos: user.APELLIDOS,
                cargo: user.CARGO,
                empresa: user.EMPRESA,
              })
              .then(() => {
                updated += 1;
              })
              .catch((e) => {
                errs += 1;
              })
              .finally(() => {
                showCompleteDialog(i, users.length - 1, created, updated, errs);
              });;
          });
        } else {
          crearUsuario({
            tipoDoc: user.TIPODOC,
            nroDoc: user.NRODOC.toString(),
            nombres: user.NOMBRES,
            apellidos: user.APELLIDOS,
            cargo: user.CARGO,
            empresa: user.EMPRESA,
            email: user.EMAIL,
            password: user.PASSWORD.toString(),
            estado: "enabled",
            connected: false,
            tipoUsuario: "Normal",
            token: "",
          })
            .then(() => {
              created += 1;
            })
            .catch((e) => {
              errs += 1;
            })
            .finally(() => {
              showCompleteDialog(i, users.length - 1, created, updated, errs);
            });
        }
      } else {
        errs += 1;
      }
    }
  }

  function showCompleteDialog(i, size, created, updated, errs) {
    if (i === size) {
      Swal.close()
      Swal.fire(
        {
          title: "Excel Importado",
          html: "Usuarios creados: " + created + ".<br/>Usuarios actualizados: " + updated + ".<br/>Errores: " + errs,
          icon: "info"
        });
    }
  }

  function confirmDialog(nombres, apellidos, id) {
    Swal.fire({
      title: "Eliminar usuario:\n" + nombres + " " + apellidos,
      text:
        "Si confirma, eliminará todos los datos del usuario. ¿Está seguro de continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        spinnerModal();
        const borrarUser = app.functions().httpsCallable("borrarUsuario");

        borrarUser({ idUserAuth: id })
          .then(() => {
            Swal.fire("Eliminado!", "El usuario ha sido eliminado.", "success");
          })
          .catch((e) => {
            console.log(e)
            Swal.fire("Error!", "No se pudo eliminar al usuario.", "error");
          });
      }
    });
  }

  function spinnerModal() {
    let timerInterval;
    Swal.fire({
      title: "Procesando solicitud",
      html: "Espere un momento por favor.",
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("I was closed by the timer");
      }
    });
  }

  async function getData() {
    app
      .firestore()
      .collection("users")
      .orderBy("nombres", "asc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push(doc.data());
        });
        setUsers(docs);
        setUsersBuscar(docs);
        setLoading(false);
      });
  }

  function llenarData(id) {
    if (id) {
      app
        .firestore()
        .collection("users")
        .doc(id)
        .onSnapshot((snap) => setUsuario(snap.data()));
    }
  }

  function cambiarEstado(estado, id) {
    if (estado === "enabled") {
      app
        .firestore()
        .collection("users")
        .doc(id)
        .update({ estado: "disabled" });
    }
    if (estado === "disabled") {
      app.firestore().collection("users").doc(id).update({ estado: "enabled" });
    }
    if (estado === "pending") {
      app.firestore().collection("users").doc(id).update({ estado: "enabled" });
    }
  }

  function borrarUsuario(nombres, apellidos, id) {
    confirmDialog(nombres, apellidos, id);
  }

  function setAdmin(nombres, apellidos, tipoUsuario, id) {
    const admin = tipoUsuario === "Administrador";
    Swal.fire({
      title: admin ? "Retirar privilegios de administrador a:\n" + nombres + " " + apellidos : "Proporcionar privilegios de administrador a:\n" + nombres + " " + apellidos,
      text: admin ? "Si confirma, el usuario ya no tendrá acceso al sistema como administrador ¿Está seguro de continuar?" : "Si confirma, el usuario tendrá acceso al sistema como administrador ¿Está seguro de continuar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: admin ? "Quitar privilegios" : "Dar privilegios",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        spinnerModal();
        app
          .firestore()
          .collection("users")
          .doc(id)
          .update({ tipoUsuario: admin ? "Normal" : "Administrador" })
          .then(() => {
            Swal.fire("¡Listo!", admin ? "El usuario ya no es administrador." : "El usuario ahora es administrador.", "success");
          })
          .catch(() => {
            Swal.fire("Error!", "No se pudo actualizar al usuario.", "error");
          });
      }
    });
  }

  function buscar(e) {
    const valor = e.toLowerCase();
    const usuarios = usersBuscar.filter(
      (user) =>
        (user.nombres && user.nombres.toLowerCase().search(valor) >= 0) ||
        (user.apellidos && user.apellidos.toLowerCase().search(valor) >= 0) ||
        (user.cargo && user.cargo.toLowerCase().search(valor) >= 0) ||
        (user.empresa && user.empresa.toLowerCase().search(valor) >= 0) ||
        (user.tipoDoc && user.tipoDoc.toLowerCase().search(valor) >= 0) ||
        (user.idUserAuth && user.idUserAuth.toLowerCase().search(valor) >= 0) ||
        (user.email && user.email.toLowerCase().search(valor) >= 0) ||
        (user.tipoUsuario && user.tipoUsuario.toLowerCase().search(valor) >= 0)
    );
    setUsers(usuarios);
  }

  function usuariosTodos() {
    app
      .firestore()
      .collection("users")
      .orderBy("nombres", "asc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push(doc.data());
        });
        setUsers(docs);
        setUsersBuscar(docs);
      });
  }
  function usuariosHabilitados() {
    app
      .firestore()
      .collection("users")
      .where("estado", "==", "enabled")
      .orderBy("nombres", "asc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push(doc.data());
        });
        setUsers(docs);
        setUsersBuscar(docs);
      });
  }
  function usuariosDeshabilitados() {
    app
      .firestore()
      .collection("users")
      .where("estado", "==", "disabled")
      .orderBy("nombres", "asc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push(doc.data());
        });
        setUsers(docs);
        setUsersBuscar(docs);
      });
  }
  function usuariosPendientes() {
    app
      .firestore()
      .collection("users")
      .where("estado", "==", "pending")
      .orderBy("nombres", "asc")
      .onSnapshot((snap) => {
        const docs = [];
        snap.forEach((doc) => {
          docs.push(doc.data());
        });
        setUsers(docs);
        setUsersBuscar(docs);
      });
  }

  useEffect(() => {
    getData();
    //notificationState();
  }, []);

  const usuarios = (
    <>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">
                  {" "}
                  {/*Top Navigation*/} <small></small>
                </h1>
              </div>
              {/* /.col */}
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="container">
          <div className="content">
            <div className="row">
              {/* /.col-md-6 */}
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <input
                        type="text"
                        name="q"
                        onChange={(e) => buscar(e.target.value)}
                        className="form-control m-1 col-md-6"
                        placeholder="Buscar..."
                      />

                      <div className="col-md-6 row d-flex justify-content-end">
                        <button
                          className="btn bg-gradient-success m-1 col-md-5"
                          data-toggle="modal"
                          data-target="#modal-lg"
                        >
                          <i className="fas fa-user-plus mr-2"></i>
                          Agregar Usuario
                        </button>

                        <input className="btn bg-gradient-success m-1 col-md-5" type="file" accept=".xlsx" id="upload-excel" onChange={ExcelAJSON}></input>

                      </div>
                      <div className="col-md-12 mt-2">Filtar por:</div>
                      <div
                        className="btn-group btn-group-toggle m-1"
                        data-toggle="buttons"
                      >
                        <label
                          onClick={() => usuariosTodos()}
                          className="btn btn-primary mr-1 active"
                        >
                          <input
                            type="radio"
                            name="options"
                            id="option_a1"
                            autoComplete="off"
                            defaultChecked
                          />{" "}
                          Todos
                        </label>
                        <label
                          onClick={() => usuariosHabilitados()}
                          className="btn btn-primary mr-1"
                        >
                          <input
                            type="radio"
                            name="options"
                            id="option_a1"
                            autoComplete="off"
                            defaultChecked
                          />{" "}
                          Habilitados
                        </label>
                        <label
                          onClick={() => usuariosDeshabilitados()}
                          className="btn btn-primary mr-1"
                        >
                          <input
                            type="radio"
                            name="options"
                            id="option_a2"
                            autoComplete="off"
                          />{" "}
                          Inhabilitados
                        </label>
                        <label
                          onClick={() => usuariosPendientes()}
                          className="btn btn-primary mr-1"
                        >
                          <input
                            type="radio"
                            name="options"
                            id="option_a3"
                            autoComplete="off"
                          />{" "}
                          Pendientes
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}

                  <Table
                    dataSource={users}
                    columns={columns}
                    loading={loading}
                    bordered
                    scroll={{ x: "max-content", y: 500 }}
                    rowClassName={(_, index) => {
                      if (index % 2 === 0) {
                        return "";
                      } else {
                        return "";
                      }
                    }}
                    rowKey={(record) => record.idUserAuth}
                  />
                </div>
                {/* /.card */}
              </div>
              {/* /.col-md-6 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/*----------------- MODAL-------------------- */}
      <div className="modal fade" id="modal-lg">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="login-logo" style={{ color: "#0B3688" }}>
                Registro de Usuario
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <Registro></Registro>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
      {/*----------------- MODAL EDITAR----------------- */}
      <div className="modal fade" id="modal-lg-edit">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <EditarUsuario datosUsuario={usuario}></EditarUsuario>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>
    </>
  );
  return usuarios;
}
