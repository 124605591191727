import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import app from "../../../firebase-config";

function EstadisticasCard() {
  const [hrs, setHrs] = useState([]);
  const [alarmas, setAlarmas] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [precauciones, setPrecauciones] = useState([]);
  const [noRDs, setNoRDs] = useState([]);
  const [fallas, setFallas] = useState([]);
  const [ultimaHora, setUltimaHora] = useState(new Date().getTime());

  async function getData() {
    await app
      .firestore()
      .collection("sensor")
      .orderBy("hora", "desc")
      .limit(1)
      .onSnapshot((snap) => {
        snap.forEach((doc) => {
          setUltimaHora(parseInt(doc.data().hora));
        });
      });
    await app
      .firestore()
      .collection("sensor")
      .orderBy("hora", "asc")
      .onSnapshot((snap) => {
        const docHrs = [];
        const docsAlarmas = [];
        const docsAvisos = [];
        const docsPracauciones = [];
        const docsNoRDs = [];
        const docsFallas = [];

        snap.forEach((doc) => {
          docHrs.push(new Date(parseInt(doc.data().hora)).toLocaleTimeString());
          if (doc.data().estado === 5) {
            docsAlarmas.push(parseInt(doc.data().hora));
          }
          if (doc.data().estado === 4) {
            docsAvisos.push(parseInt(doc.data().hora));
          }
          if (doc.data().estado === 3) {
            docsPracauciones.push(parseInt(doc.data().hora));
          }
          if (doc.data().estado === 2) {
            docsNoRDs.push(parseInt(doc.data().hora));
          }
          if (doc.data().estado === 1) {
            docsFallas.push(parseInt(doc.data().hora));
          }
        });
        setAlarmas(docsAlarmas);
        setAvisos(docsAvisos);
        setPrecauciones(docsPracauciones);
        setNoRDs(docsNoRDs);
        setFallas(docsFallas);
      });
  }

  /*-----------------Frecuencia de Estados----------------------- */
  function frecuenciaFalla(ultimaHora, arrayHrs, arraySts) {
    var cont1 = 0;
    var cont2 = 0;
    var cont3 = 0;
    var cont4 = 0;
    var cont5 = 0;
    var cont6 = 0;
    arraySts.forEach((val, i) => {
      if (arrayHrs[0] < val && val < arrayHrs[1]) {
        cont1++;
      }
      if (arrayHrs[1] < val && val < arrayHrs[2]) {
        cont2++;
      }
      if (arrayHrs[2] < val && val < arrayHrs[3]) {
        cont3++;
      }
      if (arrayHrs[3] < val && val < arrayHrs[4]) {
        cont4++;
      }
      if (arrayHrs[4] < val && val < arrayHrs[5]) {
        cont5++;
      }
      if (arrayHrs[5] < val && val < ultimaHora) {
        cont6++;
      }
    });
    const frec = [cont1, cont2, cont3, cont4, cont5, cont6];
    return frec;
  }
  function frecuenciaNoRD(ultimaHora, arrayHrs, arraySts) {
    var cont1 = 0;
    var cont2 = 0;
    var cont3 = 0;
    var cont4 = 0;
    var cont5 = 0;
    var cont6 = 0;
    arraySts.forEach((val, i) => {
      if (arrayHrs[0] < val && val < arrayHrs[1]) {
        cont1++;
      }
      if (arrayHrs[1] < val && val < arrayHrs[2]) {
        cont2++;
      }
      if (arrayHrs[2] < val && val < arrayHrs[3]) {
        cont3++;
      }
      if (arrayHrs[3] < val && val < arrayHrs[4]) {
        cont4++;
      }
      if (arrayHrs[4] < val && val < arrayHrs[5]) {
        cont5++;
      }
      if (arrayHrs[5] < val && val < ultimaHora) {
        cont6++;
      }
    });
    const frec = [cont1, cont2, cont3, cont4, cont5, cont6];
    return frec;
  }
  function frecuenciaPrecaucion(ultimaHora, arrayHrs, arraySts) {
    var cont1 = 0;
    var cont2 = 0;
    var cont3 = 0;
    var cont4 = 0;
    var cont5 = 0;
    var cont6 = 0;
    arraySts.forEach((val, i) => {
      if (arrayHrs[0] < val && val < arrayHrs[1]) {
        cont1++;
      }
      if (arrayHrs[1] < val && val < arrayHrs[2]) {
        cont2++;
      }
      if (arrayHrs[2] < val && val < arrayHrs[3]) {
        cont3++;
      }
      if (arrayHrs[3] < val && val < arrayHrs[4]) {
        cont4++;
      }
      if (arrayHrs[4] < val && val < arrayHrs[5]) {
        cont5++;
      }
      if (arrayHrs[5] < val && val < ultimaHora) {
        cont6++;
      }
    });
    const frec = [cont1, cont2, cont3, cont4, cont5, cont6];
    return frec;
  }
  function frecuenciaAviso(ultimaHora, arrayHrs, arraySts) {
    var cont1 = 0;
    var cont2 = 0;
    var cont3 = 0;
    var cont4 = 0;
    var cont5 = 0;
    var cont6 = 0;
    arraySts.forEach((val, i) => {
      if (arrayHrs[0] < val && val < arrayHrs[1]) {
        cont1++;
      }
      if (arrayHrs[1] < val && val < arrayHrs[2]) {
        cont2++;
      }
      if (arrayHrs[2] < val && val < arrayHrs[3]) {
        cont3++;
      }
      if (arrayHrs[3] < val && val < arrayHrs[4]) {
        cont4++;
      }
      if (arrayHrs[4] < val && val < arrayHrs[5]) {
        cont5++;
      }
      if (arrayHrs[5] < val && val < ultimaHora) {
        cont6++;
      }
    });
    const frec = [cont1, cont2, cont3, cont4, cont5, cont6];
    return frec;
  }
  function frecuenciaAlarma(ultimaHora, arrayHrs, arraySts) {
    var cont1 = 0;
    var cont2 = 0;
    var cont3 = 0;
    var cont4 = 0;
    var cont5 = 0;
    var cont6 = 0;
    arraySts.forEach((val, i) => {
      if (arrayHrs[0] < val && val < arrayHrs[1]) {
        cont1++;
      }
      if (arrayHrs[1] < val && val < arrayHrs[2]) {
        cont2++;
      }
      if (arrayHrs[2] < val && val < arrayHrs[3]) {
        cont3++;
      }
      if (arrayHrs[3] < val && val < arrayHrs[4]) {
        cont4++;
      }
      if (arrayHrs[4] < val && val < arrayHrs[5]) {
        cont5++;
      }
      if (arrayHrs[5] < val && val < ultimaHora) {
        cont6++;
      }
    });
    const frec = [cont1, cont2, cont3, cont4, cont5, cont6];
    return frec;
  }
  /*-----------------Frecuencia de Estados----------------------- */
  function horasFormat(hrs) {
    const array = [];
    hrs.forEach((hr) => {
      array.push(new Date(hr).toLocaleDateString());
    });
    array.splice(0, 1)
    array.push(new Date().toLocaleDateString());
    return array;
  }

  useEffect(() => {
    getData().then(() => {
      const haceUnaHora = ultimaHora - 86400000;
      const haceDosHora = ultimaHora - 86400000 * 2;
      const haceTresHora = ultimaHora - 86400000 * 3;
      const haceCuatroHora = ultimaHora - 86400000 * 4;
      const haceCincoHora = ultimaHora - 86400000 * 5;
      const haceSeisHora = ultimaHora - 86400000 * 6;
      const arrayHrs = [
        haceSeisHora,
        haceCincoHora,
        haceCuatroHora,
        haceTresHora,
        haceDosHora,
        haceUnaHora,
      ];
      setHrs(arrayHrs);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (hrs.length === 6) {
    return (
      <div className="row">
        <div className="col-md-8">
          <p className="text-center">
            <strong>Estados detectados / Tiempo</strong>
          </p>
          <Line
            data={{
              labels: horasFormat(hrs),
              datasets: [
                {
                  label: "Alarma",
                  data: frecuenciaAlarma(ultimaHora, hrs, alarmas),
                  backgroundColor: ["rgba(255,0,0,0.3)"],
                  borderColor: ["rgba(255,0,0,1)"],
                  fill: true,
                },
                {
                  label: "Aviso",
                  data: frecuenciaAviso(ultimaHora, hrs, avisos),
                  backgroundColor: ["rgba(255,165,0,0.3)"],
                  borderColor: ["rgba(255,165,0,1)"],
                  fill: true,
                },
                {
                  label: "Precaución",
                  data: frecuenciaPrecaucion(ultimaHora, hrs, precauciones),
                  backgroundColor: ["rgba(255,255,0,0.3)"],
                  borderColor: ["rgba(255,255,0,1)"],
                  fill: true,
                },
                {
                  label: "No Relámpago Detectado",
                  data: frecuenciaNoRD(ultimaHora, hrs, noRDs),
                  backgroundColor: ["rgba(0,187,45,0.3)"],
                  borderColor: ["rgba(0,255,0,1)"],
                  fill: true,
                },
                {
                  label: "Falla",
                  data: frecuenciaFalla(ultimaHora, hrs, fallas),
                  backgroundColor: ["rgba(128,128,128,0.3)"],
                  borderColor: ["rgba(128,128,128,1)"],
                  fill: true,
                },
              ],
            }}
            options={{}}
          ></Line>
        </div>
        <div className="col-md-4">
          <p className="text-center">
            <strong>
              Total de Estados/ Promedio(
              {fallas.length +
                noRDs.length +
                precauciones.length +
                avisos.length +
                alarmas.length}
              )
            </strong>
          </p>
          <div className="progress-group">
            Falla
            <span className="float-right">
              <b>{fallas.length}</b>
            </span>
            <div className="progress progress-sm">
              <div
                className="progress-bar bg-info"
                style={{
                  width:
                    (100 * fallas.length) /
                    (fallas.length +
                      noRDs.length +
                      precauciones.length +
                      avisos.length +
                      alarmas.length) +
                    "%",
                }}
              />
            </div>
          </div>
          <div className="progress-group">
            No Relámpago Detectado
            <span className="float-right">
              <b>{noRDs.length}</b>
            </span>
            <div className="progress progress-sm">
              <div
                className="progress-bar bg-green"
                style={{
                  width:
                    (100 * noRDs.length) /
                    (fallas.length +
                      noRDs.length +
                      precauciones.length +
                      avisos.length +
                      alarmas.length) +
                    "%",
                }}
              />
            </div>
          </div>
          <div className="progress-group">
            <span className="progress-text">Precaución</span>
            <span className="float-right">
              <b>{precauciones.length}</b>
            </span>
            <div className="progress progress-sm">
              <div
                className="progress-bar"
                style={{
                  backgroundColor: "rgba(255,255,0,1)",
                  width:
                    (100 * precauciones.length) /
                    (fallas.length +
                      noRDs.length +
                      precauciones.length +
                      avisos.length +
                      alarmas.length) +
                    "%",
                }}
              />
            </div>
          </div>
          <div className="progress-group">
            Aviso
            <span className="float-right">
              <b>{avisos.length}</b>
            </span>
            <div className="progress progress-sm">
              <div
                className="progress-bar bg-orange"
                style={{
                  width:
                    (100 * avisos.length) /
                    (fallas.length +
                      noRDs.length +
                      precauciones.length +
                      avisos.length +
                      alarmas.length) +
                    "%",
                }}
              />
            </div>
          </div>
          <div className="progress-group">
            Alarma
            <span className="float-right">
              <b>{alarmas.length}</b>
            </span>
            <div className="progress progress-sm">
              <div
                className="progress-bar bg-danger"
                style={{

                  width:
                    (100 * alarmas.length) /
                    (fallas.length +
                      noRDs.length +
                      precauciones.length +
                      avisos.length +
                      alarmas.length) +
                    "%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Cargando...</div>;
  }
}

export default EstadisticasCard;
